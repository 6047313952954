.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  > li {
    margin: 0;
    padding: 1rem 0;
  }

  li + li {
    border-top: 1px solid $gray-lighter;
  }
}

.accordion-open {
  .accordion-item-content {
    @include show-accordion;
  }

  .accordion-item-icon {
    transform: rotate(180deg);
  }
}

.accordion-closed {
  .accordion-item-content {
    @include hide-accordion;
  }

  .accordion-item-icon {
    transform: rotate(0);
  }
}

.accordion-item-icon {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  transition: transform 0.3s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
  opacity: 0.6;
}

.accordion-item-container {
  > h5 {
    margin: 0;
    padding: 0;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}

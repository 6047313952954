.type-info {
  border-left: 5px solid $docs-color-success;
  margin-bottom: 0.5em;

  &.type-info-green {
    border-left-color: $docs-color-success;
  }

  &.type-info-pink {
    border-left-color: $docs-color-heart;
  }

  &.type-info-blue {
    border-left-color: $docs-color-secondary;
  }

  &.type-info-orange {
    border-left-color: $docs-color-warning;
  }

  &.type-info-purple {
    border-left-color: $docs-color-purple;
  }

  &.type-info-red {
    border-left-color: $docs-color-danger;
  }

  a {
    color: $docs-color-back;
  }

  ul.interface-map {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    &:empty {
      display: none;
    }

    li {
      margin: 0.5em 0;
    }
  }

  span.block {
    display: block;
    word-break: break-word;
  }

  span.comment {
    margin-top: 0.7rem;
    margin-bottom: 0.3rem;
  }

  span.block span.comment {
    color: #bbb;

    &::before {
      content: '//';
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  code {
    background: lighten($color: $docs-color-text, $amount: 14%);
    color: $docs-color-back;
    font-size: 14px;
    font-weight: bold;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    padding: 2px 8px;
    padding-top: 4px;
    display: inline-block;
  }
}

.type-title {
  cursor: pointer;
  background: $docs-color-shadow;
  padding: 1em;

  > b {
    color: transparentize($color: $docs-color-text, $amount: 0.2);
    font-size: 0.9em;
  }

  > h3 {
    margin: 0.3em 0;
  }

  > span.block {
    margin: 0.7em 0;
  }
}

.type-details {
  overflow: hidden;
  transition: opacity 500ms ease;

  &.open {
    padding: 0.5em;
    max-height: 100000;
    opacity: 1;
  }

  &.close {
    padding: 0;
    max-height: 0;
    opacity: 0;
  }
}

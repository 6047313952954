body.dark {
  color: $docs-color-text-dark;

  img {
    filter: brightness(0.8) contrast(1.2);

    &.auto {
      filter: #{"invert() hue-rotate(180deg)"};
    }
  }
}

.dm-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  user-select: none;
  box-sizing: border-box;
  margin-left: 1rem;
}

.dm-toggle-thumb {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;

  body.dark & {
    transform: translateX(26px);
    border-color: #19ab27;
  }
}

.dm-toggle-sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  box-sizing: border-box;
}

.dm-toggle-track-x, .dm-toggle-track-check {
  position: absolute;
  width: 17px;
  height: 17px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
}

.dm-toggle-track-x {
  right: 5px;

  body.dark & {
    opacity: 1;
    transition: opacity 0.25s ease;
  }
}

.dm-toggle-track-check {
  left: 5px;
}

.dm-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #0f1114;
  transition: all 0.2s ease;
  box-sizing: border-box;

  img {
    pointer-events: none;
  }
}

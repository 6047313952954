@use 'sass:math';

$border-width: 8%;
$basic-spinner-dimensions: 50px;
$main-spinner-dimensions: 100% - $border-width * 2;
$small-spinner-dimensions: $main-spinner-dimensions * 0.7;

.spinner {
  position: relative;
  width: $basic-spinner-dimensions;
  height: $basic-spinner-dimensions;
  font-size: 0;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    border-width: $border-width;
    border-style: solid;
    border-radius: 50%;
  }
}

.spinner.circles {
  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes anti-rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }

  &:before {
    width: $main-spinner-dimensions;
    height: $main-spinner-dimensions;
    border-bottom-color: $primary;
    border-right-color: $primary;
    border-top-color: transparent;
    border-left-color: transparent;
    top: 0;
    left: 0;
    animation: rotate-animation 1s linear 0s infinite;
  }

  &:after {
    width: $small-spinner-dimensions;
    height: $small-spinner-dimensions;
    border-bottom-color: $primary;
    border-right-color: $primary;
    border-top-color: transparent;
    border-left-color: transparent;
    top: math.div($main-spinner-dimensions - $small-spinner-dimensions, 2);
    left: math.div($main-spinner-dimensions - $small-spinner-dimensions, 2);
    animation: anti-rotate-animation 0.85s linear 0s infinite;
  }
}

div.content {
  display: flex;
  flex: 1 0 auto;
  width: 100%;

  .section-nav {
    position: sticky;
    top: 1rem;
    align-self: flex-start;
    width: 240px;
    padding: 1.5rem 0;
    overflow: hidden;
    height: calc(100vh - 10rem);
    box-sizing: border-box;

    &:empty {
      width: 0;
    }

    @media (max-width: $docs-tablet-brk) {
      display: none;
    }
  }

  .toc-nav {
    position: sticky;
    top: 1rem;
    align-self: flex-start;
    width: 240px;
    padding: 1.5rem 0;
    overflow: hidden;
    order: 2;

    &:empty {
      width: 0;
    }

    @media (max-width: $docs-screen-brk) {
      display: none;
    }
  }

  .content-display {
    flex: 1;
    padding: 1.5rem;

    @media (max-width: $docs-tablet-brk) {
      padding: 1.5rem 0.5rem;
    }
  }
}

.scroller {
  scrollbar-width: thin;
  scrollbar-color: $docs-color-scrollbar transparent;
  overflow-y: auto;
  backface-visibility: hidden;
  scroll-snap-type: y mandatory;
  touch-action: pan-y;
  max-height: 100%;

  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $docs-color-scrollbar;
  }
}

.icon {
  position: absolute;
  z-index: 2;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;

  svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto;
    fill: currentcolor;
  }
}

.version-info {
  margin-top: 0.5rem;
  flex: 1 0 280px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-size: 0.8em;
  color: $docs-color-tabs-border-normal;

  > div {
    margin-left: 1rem;
  }

  i {
    margin: 0 0.5rem;
  }

  @media (min-width: $docs-desktop-brk) {
    flex: 0 0 auto;
    justify-content: space-evenly;
    margin-top: 0;
  }

  @media (max-width: $docs-mobile-brk) {
    display: none;
  }
}

.not-found-teaser {
  max-width: 100%;
  width: 600px;
  margin: 0 auto;
  display: block;
}

.layout-container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (min-width: $docs-tablet-brk) {
    max-width: 720px;
  }

  @media (min-width: $docs-desktop-brk) {
    max-width: 960px;
  }

  @media (min-width: $docs-screen-brk) {
    max-width: 1140px;
  }

  @media (min-width: 1300px) {
    max-width: 1220px;
  }

  @media (min-width: 1400px) {
    max-width: 1300px;
  }

  @media (min-width: 1500px) {
    max-width: 1400px;
  }

  @media (min-width: 1600px) {
    max-width: 1500px;
  }
}

@use 'sass:math';

.input-tag__container {
  display: flex;
  flex-direction: column;
  background: $text-background;
  padding: 0rem 1rem;
  border-bottom: 3px solid #8e8989;

  label.text-floating {
    padding-bottom: 0.5rem;
    padding-left: 0;
  }

  &:focus,
  &:focus-within {
    border-bottom: 3px solid $primary;
  }

  &:focus,
  &:focus-within,
  &.active {
    box-shadow: none;
    background: $text-background;
  }

  &.error {
    border-bottom: 3px solid $danger;
  }
}

.input-tag {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0 0;
}

.input-tag input {
  border: none;
  width: 100%;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__tags li {
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-size: .8em;
  font-weight: 700;
  list-style: none;
  margin-bottom: 5px;
}

.input-tag__tags li button {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 50%;
  color: rgba(0,0,0,.6);
  cursor: pointer;
  display: inline-flex;
  font-size: 20px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  transform: rotate(45deg);
  width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;

  [contenteditable] {
    outline: 0px solid transparent;
    border: 1px dashed rgba(0,0,0,.6);
    min-width: 0;

    &:empty:before {
      content: "\200b";
    }
  }
}

.tag-search-result {
  position: absolute;
  width: 18.75rem;
  max-width: 60vw;
  max-height: 18.75rem;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  overflow: hidden;
  z-index: 9;
  overflow-y: visible;
  width: 100%;
  margin-top: 2px;
  top: 100%;

  &.is-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;

    .spinner {
      margin: 1rem;
      height: 32px;
      width: 32px;
    }
  }

  ul {
    margin: 0;
    width: 100%;
    max-width: 100%;

    li {
      cursor: pointer;
      display: flex;
      position: relative;


    }
    li.active,
    li:hover {
      background-color: $gray;
    }

    div {
      margin-left: 0.625rem;
    }
  }
}

ul.keywords {
  list-style: none;
  margin: 0 -0.3em;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    border-radius: 7px;
    background: $docs-color-shadow;
    color: $docs-color-sidebar-active;
    padding: 0.5em;
    margin: 1em 0.3em;
  }
}

section {
  font-size: 1.2em;
  padding: 1em 0 3em;

  &.dark-background {
    background: $gray-lighter;
  }

  &.light-background {
    background: $text-contrast;
  }

  h1,
  .h1 {
    margin: 2em 0;
    text-align: center;

    &::after {
      width: 3em;
      height: 3px;
      background: $primary;
      content: '';
      display: block;
      margin: 5px auto 0;
    }
  }

  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    font-weight: normal;
  }

  p,
  h1,
  h2,
  h3,
  ol,
  ul,
  .text,
  .message-frame {
    width: 100%;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .text,
  p,
  h1,
  h2,
  h3,
  ol,
  ul {
    line-height: 1.5;
  }

  p {
    text-align: justify;
  }

  ol,
  ul {
    padding: 0;
    list-style-position: inside;

    li>h4 {
      display: inline;
    }
  }

  ol.latin {
    list-style-type: lower-latin;
  }
}

.tabs {
  .tabs-header {
    border-bottom: 1px solid $docs-color-tabs-border-normal;
    position: relative;

    ul {
      display: block;
      list-style: none;
      border: none;
      margin: 0;
      padding: 0;

      li {
        margin-right: 1em;
        display: inline-block;
        width: auto;

        a {
          padding: 0 0 0.5em;
          display: inline-block;
          width: auto;
          color: $docs-color-tabs-text-normal;
          text-decoration: none;

          &.active {
            border-bottom: 2px solid $docs-color-tabs-border-active;
            color: $docs-color-tabs-text-active;
          }
        }
      }
    }
  }

  .tabs-content {
    margin-top: 2rem;

    .tab {
      &.visible {
        display: block;
      }

      &.hidden {
        display: none;
      }
    }
  }
}

.actions-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  @media (max-width: $desktop-brk) {
    justify-content: flex-start;

    >*:not(:first-child) {
      padding-left: 1em;
    }

    >*::after {
      padding-left: 0.5em;
      content: attr(title);
    }
  }
}

.actions-list {
  font-size: 1rem;
  list-style: none;
  max-width: 100%;
  margin: 0;

  > li {
    margin: 0.5em 0;
  }
}

a[disabled] {
  color: $gray-darker;
  pointer-events: none;
}

.action-item {
  display: inline-flex;
  align-items: center;
  font-size: 1em;
  gap: 1rem;

  i {
    width: 1em;
  }
}

.box, details {
  margin: 1.5625em 0;
  padding: 0 0.6rem;
  overflow: hidden;
  color: $docs-color-text;
  font-size: 1rem;
  page-break-inside: avoid;
  background-color: $docs-color-back;
  border-left: 0.3rem solid $docs-color-secondary;
  border-radius: 0.1rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05), 0 0 0.05rem rgba(0, 0, 0, 0.1);

  body.dark & {
    background-color: $docs-color-back-dark;
    color: $docs-color-text-dark;
  }

  .box-title {
    position: relative;
    margin: 0 -0.6rem 1rem;
    padding: 0.4rem 0.6rem 0.4rem 2rem;
    font-weight: 700;

    &::before {
      position: absolute;
      font-family: "Font Awesome 5 Free";
      font-size: 0.875rem;
      text-align: center;
      top: 0.6rem;
      left: 0.6rem;
      width: 1rem;
      height: 1rem;
      content: "";
    }
  }

  &.tip {
    border-color: $docs-color-tip;

    .box-title {
      background-color: transparentize($color: $docs-color-tip, $amount: 0.9);

      &::before {
        color: $docs-color-tip;
        content: "\f06d";
      }
    }
  }

  &.warning {
    border-color: $docs-color-warning;

    .box-title {
      background-color: transparentize($color: $docs-color-warning, $amount: 0.9);

      &::before {
        color: $docs-color-warning;
        content: "\f071";
      }
    }
  }

  &.failure {
    border-color: $docs-color-danger;

    .box-title {
      background-color: transparentize($color: $docs-color-danger, $amount: 0.9);

      &::before {
        color: $docs-color-danger;
        content: "\f057";
      }
    }
  }

  &.success {
    border-color: $docs-color-success;

    .box-title {
      background-color: transparentize($color: $docs-color-success, $amount: 0.9);

      &::before {
        color: $docs-color-success;
        content: "\f058";
      }
    }
  }

  &.question {
    border-color: $docs-color-purple;

    .box-title {
      background-color: transparentize($color: $docs-color-purple, $amount: 0.9);

      &::before {
        color: $docs-color-purple;
        content: "\f059";
      }
    }
  }

  &.summary {
    border-color: $docs-color-secondary;

    .box-title {
      background-color: transparentize($color: $docs-color-secondary, $amount: 0.9);

      &::before {
        color: $docs-color-secondary;
        content: "\f02d";
      }
    }
  }
}

details {
  display: block;
  padding-top: 0;
  overflow: visible;

  &:not([open]) {
    padding-bottom: 0;

    & > summary {
      border-bottom-right-radius: 0.1rem;
    }
  }

  &[open] > summary::after {
    transform: rotate(90deg);
  }

  summary {
    position: relative;
    margin: 0 -0.6rem;
    font-weight: 700;
    display: block;
    min-height: 1rem;
    padding: 0.4rem 1.8rem 0.4rem 2rem;
    border-top-right-radius: 0.1rem;
    cursor: pointer;
    background-color: transparentize($color: $docs-color-secondary, $amount: 0.9);

    &::-webkit-details-marker {
      display: none;
    }

    &:first-of-type {
      list-style-type: none;
    }

    &::before, &::after {
      position: absolute;
      font-family: "Font Awesome 5 Free";
      font-size: 0.875rem;
      top: 0.6em;
      text-align: center;
      width: 1rem;
      height: 1rem;
    }

    &::before {
      left: 0.6rem;
      color: $docs-color-secondary;
      content: "\f02d";
    }

    &::after {
      right: 0.6rem;
      color: currentcolor;
      transform: rotate(0deg);
      transition: transform 250ms;
      content: "\f054";
    }
  }
}

.doc-container {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  width: 100%;

  .doc-sidebar {
    flex: 0 0 250px;
    align-self: flex-start;
    padding-right: 1em;
    box-sizing: border-box;

    @media (max-width: $docs-mobile-brk) {
      display: none;
    }
  }

  .doc-content {
    flex: 1;
    width: 100%;
  }
}

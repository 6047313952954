@use 'sass:math';

form {
  max-width: 700px;
  margin: 0 auto;
}

.radio-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: $text-background;
  margin-bottom: 1.5em;

  .form-group {
    margin: 0;
  }
}

.search-container {
  position: relative;
  width: 18em;
  max-width: 100%;

  input[type=search] {
    background: $text-background;
    padding: 1em 2em 1em 1em;
    border: none;
    box-sizing: border-box;
    width: 100%;
  }

  i {
    position: absolute;
    color: $gray-darker;
    top: 0;
    bottom: 0;
    right: 0.5em;
    margin: auto;
    height: 1em;
  }
}

$range-thumb-height: 15px;

.form-group-range {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;

  input[type=range].form-control {
    margin: 0;
    padding: 0;
    appearance: none;
    cursor: pointer;
    outline: none;
    border-radius: $range-thumb-height;
    border: 0;
    height: 0.25rem;
    background: $gray-faded;

    &::-webkit-slider-thumb {
      background-color: $primary;
      border-radius: 50%;
      border: none;
      appearance: none;
      height: $range-thumb-height;
      width: $range-thumb-height;
      transition: .2s ease-in-out;

      &:hover {
        box-shadow: 0 0 0 0.5rem $text-faded;
      }
      &:active, &:focus {
        box-shadow: 0 0 0 0.75rem $primary-lighter;
      }
    }

    &::-moz-range-thumb {
      background-color: $primary;
      border-radius: 50%;
      border: none;
      height: $range-thumb-height;
      width: $range-thumb-height;
      transition: .2s ease-in-out;
      
      &:hover {
        box-shadow: 0 0 0 0.5rem $text-faded;
      }
      &:active, &:focus {
        box-shadow: 0 0 0 0.75rem $primary-lighter;
      }
    }
  }
}

.form-group {
  display: flex;
  position: relative;
  flex-direction: column;
  background: $text-background;
  margin-bottom: 1.5em;
  transition: all .4s;

  & > .form-group {
    margin-bottom: 0;
  }

  & > p {
    margin: 0;
    padding: 1em;
    padding-top: 0.5em;
    box-sizing: border-box;
  }

  .form-control {
    display: block;
    width: 100%;
    line-height: 1.5;
    background: $text-background;
    outline: none;
    border: none;
    border-bottom: 3px solid $gray-darker;
    margin-top: -$space-input-label;
    padding: 1em;
    box-sizing: border-box;
    font-size: 1rem;

    select {
      background: transparent;
      padding-right: 1em;
      width: 100%;
      border: 0;
      height: 1.5em;
      margin: 0;
      outline: none !important;
      border: none !important;
      box-shadow: inset 0 0 0 5px $text-background !important;

      &:focus {
        outline: none;
      }
    }

    &:focus,
    &:focus-within {
      border-bottom: 3px solid $primary;
    }

    &:focus,
    &:focus-within,
    &.active {
      box-shadow: none;
      background: $text-background;

      +label {
        transform: translateY(math.div(2 * $space-input-label, 3));
        font-size: 0.8rem;
        background: transparent;
        color: $primary;
      }
    }

    &.error {
      border-bottom: 3px solid $danger;

      +label {
        color: $danger;
      }
    }
  }

  textarea.form-control {
    resize: vertical;
  }

  label.text-floating {
    order: -1;
    padding-left: 1rem;
    transition: all .3s ease-in;
    transform: translateY($space-input-label);
    font-size: 1rem;
    pointer-events: none;
    color: $gray-darker;
    display: inline-block;
    font-weight: 400;
    height: 1.4rem;
    z-index: 1;

    &.error {
      color: $danger;
    }

    &.active {
      transform: translateY(math.div(2 * $space-input-label, 3));
      font-size: 0.8rem;
      background: transparent;
      color: $primary;
    }
  }

  .checked-container {
    display: flex;
    flex-direction: row;
    margin: 1em;
    position: relative;
    cursor: pointer;
    user-select: none;

    &[disabled] {
      pointer-events: none;
    }

    input.form-control  {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background: $primary;
      }

      &.error:checked ~ .checkmark {
        background: $danger;
      }

      &:checked + .slider {
        background-color: $primary;
      }

      &:focus + .slider {
        box-shadow: 0 0 1px $primary;
      }

      &:checked + .slider::before {
        --translate: 0.8em;
      }
    }

    .slider {
      cursor: pointer;
      background-color: $gray-darker;
      border-radius: 2em;
      position: relative;
      width: 2em;
      height: 1.2em;

      &::before {
        position: absolute;
        content: "";
        height: 1em;
        width: 1em;
        left: 0.1em;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: $text-contrast;
        transition: .4s;
        border-radius: 50%;
        transform: translateX(var(--translate, 0)) scale(var(--scale, 1));
      }

      &.progress::before, &.progress::after {
        animation: progress-bounce 1s infinite ease-in-out;
      }
    }

    .checkmark {
      height: 1.2em;
      width: 1.2em;
      background: $shadow;

      &:after {
        left: 0.45em;
        top: 0.3em;
        width: 0.15em;
        height: 0.35em;
        border: solid $text-contrast;
        border-width: 0 0.15em 0.15em 0;
        transform: rotate(45deg);
        content: "";
        position: absolute;
        display: none;
      }

      &.round {
        border-radius: 50%;
      }
    }

    span.label {
      margin-left: 0.5em;
    }

    &:hover input.form-control ~ .checkmark {
      background: $primary-lighter;
    }

    &:hover input.form-control.error ~ .checkmark {
      background: $danger-lighter;
    }

    input.form-control:checked ~ .checkmark:after {
      display: block;
    }
  }
}

@keyframes progress-bounce {
  0%, 100% {
    --scale: 0.4;
  }
  50% {
    --scale: 0.8;
  }
}

.card {
  flex: 0 0 300px;
  max-width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  i {
    font-size: 1.4em;
    color: $secondary;
  }

  small {
    font-size: 0.8rem;
    color: $gray-darker;
  }
}

.member-card {
  flex: 0 0 200px;
  max-width: 100%;

  h5 {
    margin: 1em 0 0;
  }

  .image > img {
    border-radius: 5%;
  }

  .image-quote {
    margin-top: 1em;
  }

  .client-image {
    background: $text-contrast;
    flex-basis: 100px;
    justify-content: center;
    align-items: center;
    max-height: 100px;
    max-width: 200px;

  }

  .social {
    margin-top: 1em;

    a {
      padding: 0 0.5em;
      font-size: 0.8em;

      i {
        color: $gray-darker;
      }
    }
  }
}

.image-card {
  flex: 0 0 150px;
  max-width: 150px;

  &.details {
    flex-basis: 500px;
    max-width: 500px;

    @media screen and (max-width: $mobile-brk) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  h5 {
    margin-bottom: 0;
  }

  .image {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    >img {
      max-height: 100%;
      filter: grayscale(100%);
      transition: all 0.3s ease;
    }
  }

  .card-details {
    padding: 1em;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &:hover .image>img {
    filter: none;
  }
}

.icon-card {
  flex: 0 0 300px;
  max-width: 300px;

  p {
    color: $gray-darker;
    font-size: 1rem;
    text-align: center;
  }
}

.spaced-evenly {
  justify-content: space-evenly !important;

  > .icon-card {
    flex: 0 0 250px;
    max-width: 250px;
  }
}

.link-card {
  flex: 0 0 250px;

  h5 {
    margin-bottom: 0;
  }

  a {
    display: inline-block;
    margin-top: 0.5em;
    font-size: 1rem;
    text-decoration: none;
  }
}

.pricing-card {
  flex: 0 0 300px;
  max-width: 300px;
  margin: 1em;
  padding: 2em;
  border: 1px solid #eef0f5;
  border-radius: 0.5em;
  box-shadow: 0 1px 3px 0 rgba(11,26,56,.2);

  .image i {
    font-size: 3em;
  }

  h5 {
    margin-top: 1em;
  }

  .price {
    color: $gray-darker;
    display: flex;
    justify-content: center;
    font-size: 2em;
    letter-spacing: -1px;

    sup {
      font-size: 0.6em;
      vertical-align: middle;
      align-self: flex-start;
      padding-top: 1px;
    }

    sub {
      font-size: 0.6em;
      vertical-align: middle;
      align-self: flex-end;
      padding-bottom: 10px;
    }

    strong {
      font-size: larger;
      color: $text-foreground;
      margin: 0 0.2em;
    }
  }

  .options {
    list-style: none;
    padding: 0;
    margin: 1em 0;

    li {
      margin: 0.5em 0;
      font-size: 1rem;
    }
  }

  &.not-available {
    color: $gray-darker;
    background: #eee;

    .price strong {
      color: $gray-darker;
    }

    .image img {
      filter: grayscale(100%);
    }
  }
}

.project-card {
  flex: 0 0 520px;
  max-width: 520px;
  display: flex;

  >div {
    flex-basis: 450px;
    max-width: 450px;
    background: $gray;
    padding: 1em;
  }

  .partner-logo {
    background: $text-contrast;
    flex-basis: 150px;
    max-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-height: 100px;
    }
  }

  .text-card-content {
    h2.headline {
      font-size: 1.1em;
    }

    p {
      font-size: 1rem;
      text-align: left;
    }
  }

  .tech-logos {
    display: flex;
    flex-direction: row;

    .tech-logo {
      padding: .5em;
      height: 1.5em;

      img {
        max-height: 100%;
        filter: grayscale(100%);
      }
    }
  }

  @media screen and (max-width: $tablet-brk) {
    flex-wrap: wrap;
    justify-content: center;

    .partner-logo {
      flex-basis: 100%;
      max-width: 100%;
      height: 75px;

      img {
        max-width: 150px;
        max-height: 100%;
      }
    }
  }

  @media screen and (max-width: $mobile-brk) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.feed-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $text-background;
  background-clip: border-box;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  margin-bottom: 1.5em;

  &-header {
    padding: 0 1rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-weight: bold;
      align-items: center;
      padding: 1rem 0;
      display: flex;

      > a {
        color: inherit;
      }

      .form-group {
        margin: 0;
        margin-right: 0.5em;

        .checked-container {
          margin: 0;
        }
      }
    }

    &-actions {
      font-size: 14px;
      margin-left: auto;
      padding: 1rem 0;

      a {
        color: #bbb;

        &:hover {
          color: $secondary;
        }
      }

      a + a {
        padding-left: 1em;
      }
    }
  }

  .collapse.show {
    border-top: 1px solid $shadow;
  }

  .collapse.hide {
    display: none;
  }

  &-body {
    background-color: $text-contrast;
    border-radius: 0 0 0.25rem 0.25rem;
    flex: 1 1 auto;
    padding: 1rem;

    > div.card-content {
      margin-bottom: 1em;
    }
  }
}

@import '../../../node_modules/@smapiot/components/style/variables';
@import '../../../node_modules/@smapiot/components/style/text';
@import '../../../node_modules/@smapiot/components/style/button';
@import '../../../node_modules/@smapiot/components/style/media';
@import '../../../node_modules/@smapiot/components/style/input';
@import '../../../node_modules/@smapiot/components/style/spinner';
@import '../../../node_modules/@smapiot/components/style/section';
@import '../../../node_modules/@smapiot/components/style/hash';
@import '../../../node_modules/@smapiot/components/style/cards';
@import '../../../node_modules/@smapiot/components/style/choices';
@import '../../../node_modules/@smapiot/components/style/clipboard';
@import '../../../node_modules/@smapiot/components/style/tabs';
@import '../../../node_modules/@smapiot/components/style/panel';
@import '../../../node_modules/@smapiot/components/style/tags';
@import '../../../node_modules/@smapiot/components/style/tag';
@import '../../../node_modules/@smapiot/components/style/actions';
@import '../../../node_modules/@smapiot/components/style/stepper';
@import '../../../node_modules/@smapiot/components/style/date-field';
@import '../../../node_modules/@smapiot/components/style/accordion';

$docs-color-spinner: $primary;
$docs-color-primary: $secondary;
$docs-color-secondary: $primary;
$docs-color-sidebar-active: $primary;
$docs-color-quicknav-back: #2d5c0b;
$docs-color-header-active: $secondary;
$docs-color-header-back: $docs-color-quicknav-back;

@media (max-width: 576px) {
  .search-dialog[data-active="false"] .search-form .search-icon {
    left: -0.5rem;
  }
  .search-dialog[data-active="true"] .search-form .search-icon {
    left: 0.5rem;
  }
}

form {
  max-width: 100%;
}

.search-dialog .search-overlay {
  z-index: 2;
}

.configurator-tool {
  .empty-info {
    flex: 1;
    text-align: center;
    font-weight: bold;
  }
  
  .question-error {
    background: rgba(255, 0, 0, 0.1);
    margin: -1rem;
    padding: 1rem;
    border-radius: 5px;
  }
  
  .question-ok {
    background: rgba(0, 255, 0, 0.1);
    margin: -1rem;
    padding: 1rem;
    border-radius: 5px;
  }
  
  i.fas {
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    line-height: 1;
  }
  
  i.fa-info::before {
    content: '\2139';
  }
  
  i.fa-question::before {
    content: '\003F';
  }
  
  i.fa-exclamation::before {
    content: '\0021';
  }
  
  .container-app {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 1080px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 2rem;
  }
  
  .required {
    font-size: 0.7rem;
    color: green;
    font-weight: normal;
  }
  
  // Step Indicator Style
  .stepper-horizontal {
    gap: 0.7rem;
    flex-wrap: wrap;
  
    .stepper-step {
      padding: 0;
    }
  }
  
  // Questions Container Style
  .questions-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 3rem 0;
    gap: 3rem;
  
    .questions-answers-field {
      display: flex;
      flex: 1;
      padding: 0;
      gap: 3rem;
      flex-wrap: wrap;
  
      > div {
        flex: 100%;
      }
      
      .options {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
  
        > div {
          flex: 350px;
  
          &.q-wrapper-options {
            flex: 100%;
          }
        }
      }
  
      .question {
        font-size: 18px;
        font-weight: 700;
        display: flex;
        gap: 0.75rem;
        margin-bottom: 1rem;
      }
  
      .description {
        margin-bottom: 1.5rem;
      }
    }
  }
  
  // Buttons Style
  .buttons-container {
    display: flex;
    gap: 1.5rem;
  
    button {
      flex: 1;
      height: 2.5rem;
    }
  }
  
  // Result Section Style
  .results {
    flex: 1;
    padding: 0 0 0;
  
    div {
      font-size: 16px;
      text-align: left;
    }
  }
  
  //cards
  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    text-align: center;
    color: white;
    margin-bottom: 20px;
    gap: 12px;
  
    .card {
      flex: 1;
      background: #8e8989;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.75rem;
    }
  
    .active {
      background: #56aa1c;
    }
  
    .card-icon {
      max-width: 100px;
      max-height: 50px;
    }
  }
  
  // Tooltip
  .tooltip {
    color: gray;
    cursor: pointer;
  }
  
  // No Questions
  .no-questions {
    flex: 1;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    margin-bottom: 3rem;
  }      
}
.date-picker-icon {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;

  label + & {
    top: 2rem;
  }
}

.date-time-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > .form-group {
    flex: 1 1 300px;
  }
}

.mdp-container {
  position: absolute;
  min-height: 350px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  margin-top: 2px;
  top: 100%;
  overflow: hidden;
  padding: 25px 30px;
  z-index: 9;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mdp-time {
  min-height: 240px;
  display: grid;
  grid-template-columns: 1fr 1em 1fr 1em 1fr 1em 1fr;
}

.mdp-time-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;

  &.mdp-time-column-divider {
    color: $gray-darker;
    font-size: 1.5em;
  }

  strong {
    font-size: 1.5em;
  }
}

.mdpc-head {
  width: 300px;
  height: 53px;
}

.mdpc-body {
  width: 100%;
  margin-top: 20px;
  max-width: 600px;
}

.mdpch-button {
  float: left;
  width: 45px;
  height: 28px;
  box-sizing: border-box;
  position: relative;
}

.mdpchb-inner:hover > span {
  border-color: #555;
}

.mdpchb-inner:hover {
  cursor: pointer;
  background: #eee;
}

.mdpchb-inner {
  height: 35px;
  width: 35px;
  background: #f4f4f4;
  border-radius: 100%;
  line-height: 35px;
  text-align: center;
  position: absolute;
  top:50%;
  left: 50%;
  margin-left: -17px;
  margin-top: -17px;
}

.mdpchbi-top-arrow,
.mdpchbi-bottom-arrow,
.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after,
.mdpchbi-right-arrow,
.mdpchbi-right-arrows,
.mdpchbi-left-arrow,
.mdpchbi-left-arrows {
  display: block;
  width: 6px;
  height: 6px;
  border-left: 2px solid #888;
  border-bottom: 2px solid #888;
  position: absolute;
}

.mdpchbi-top-arrow,
.mdpchbi-bottom-arrow,
.mdpchbi-right-arrow,
.mdpchbi-right-arrows,
.mdpchbi-left-arrow,
.mdpchbi-left-arrows {
  transform: rotate(45deg);
  left: 50%;
  top:50%;
  margin-left: -2px;
  margin-top: -4px;
}

.mdpchbi-top-arrow {
  transform: rotate(135deg);
  margin-top: -2px;
  margin-left: -3px;
}

.mdpchbi-bottom-arrow {
  transform: rotate(315deg);
  margin-top: -4px;
  margin-left: -3px;
}

.mdpchbi-right-arrows,
.mdpchbi-right-arrow {
  transform: rotate(225deg);
  margin-left: -4px;
}

.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after {
  content: '';
}

.mdpchbi-left-arrows {
  margin-left: -5px;
}

.mdpchbi-right-arrows {
  margin-left: -2px;
}

.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after {
  left: 3px;
  top: -5px
}

.mdpch-container {
  float: left;
  width: 120px;
  height: 100%;
}

.mdpchc-year {
  width: 100%;
  height: 30px;
  font-size: 27px;
  color: #666;
  font-weight: 200px;
  text-align: center;
}

.mdpchc-month {
  width: 100%;
  height: 15px;
  font-size: 13px;
  color: #666;
  font-weight: 200px;
  text-align: center;
}

.cc-month,
.cc-head,
.cch-name,
.cc-body,
.cdc-day span,
.cdc-day,
.c-day-container,
.c-container {
  position: relative;
  display: block;
  float: left;
  box-sizing: border-box;
}

.c-container {
  width: 100%;
  height: 100%;
}

.cc-month {
  height: 30px;
  width: 100%;
  font-size: 16px;
  line-height: 30px;
  color: #666;
}

.cc-head {
  height: 30px;
  width: 100%;
  margin-top: 10px;
}

.cch-name {
  width: 14.285%;
  height: 30px;
  line-height: 30px;
  font-weight: 700;
  color: #666;
  font-size: 9px;
  text-align: center;
}

.cc-body {
  height: 270px;
  width: 100%;
}

.c-day-container {
  width: 14.285%;
  height: 16.6666%;
}

.cdc-day {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
  text-align: center;
}

.cdc-day span {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
}

.cdc-day span:hover {
  cursor: pointer;
  background: #eee;
}

.cdc-day span {
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  font-weight: 400;
  border-radius: 100%;
  line-height: 30px;
}

.c-day-container.disabled {
  pointer-events: none;
}

.c-day-container.current-day-disabled {
  pointer-events: none;
}

.c-day-container.disabled .cdc-day span {
  color: #ddd;
}

.c-day-container.current-day-disabled .cdc-day span {
  color: #ddd;
}

.c-day-container.disabled .cdc-day span {
  background: #fff;
}

.c-day-container.current-day-disabled .cdc-day span {
  background: #efdbca;
  color: #fff;
}

.c-day-container.highlight .cdc-day span{
  background: #efdbca;
}

.c-day-container.highlight-green .cdc-day span{
  background: $primary;
  color: white;
}

.search-dialog {
  display: flex;
  justify-content: flex-end;
  min-width: 15rem;
  flex: 999;

  @media (max-width: $docs-mobile-brk) {
    width: 0;
    min-width: 0;
  }

  .search-overlay {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: transparentize($color: $docs-color-text, $amount: 0.46);
    cursor: pointer;
    transition: width 0ms 250ms, height 0ms 250ms, opacity 250ms;
  }

  &[data-active=true] {
    @media (max-width: $docs-mobile-brk) {
      width: 100%;
      position: absolute;
      padding: 1rem;
      top: 0;
      left: 0;
      box-sizing: border-box;
    }

    .search-overlay {
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: width 0ms, height 0ms, opacity 250ms;
    }

    .search-details {
      width: 100%;

      .search-form {
        color: $docs-color-text;

        @media (max-width: $docs-mobile-brk) {
          width: 100%;
        }
      }

      button[type=reset][data-active=true] {
        pointer-events: all;
        cursor: pointer;
        opacity: 1;
      }

      .search-results {
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
        opacity: 1;

        .search-scroll {
          max-height: 75vh;

          @media (max-width: $docs-mobile-brk) {
            max-height: 90vh;
          }
        }
      }

      input {
        background: $docs-color-back;
        text-align: left;

        @media (max-width: $docs-mobile-brk) {
          padding: 0 44px;
        }
      }
    }
  }

  .search-back-icon {
    display: none;
  }

  .search-details {
    z-index: 1;
    width: 10rem;
    transition: width 250ms cubic-bezier(0.1, 0.7, 0.1, 1);
    position: relative;

    @media (max-width: $docs-mobile-brk) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .search-form {
    border-radius: 2px;
    position: relative;
    color: $docs-color-back;

    input {
      width: 100%;
      height: 36px;
      color: inherit;
      font-size: 1rem;
      background-color: $docs-color-scrollbar;
      border-radius: 2px;
      position: relative;
      z-index: 2;
      padding: 0 44px;
      text-align: center;
      text-overflow: ellipsis;
      transition: color 250ms, background-color 250ms;
      box-sizing: border-box;
      border: 0;
      outline: 0;
      -webkit-tap-highlight-color: transparent;

      &::placeholder {
        color: $docs-color-inactive;
      }
    }

    label {
      pointer-events: none;
    }

    .search-icon {
      top: 6px;
      left: 10px;
    }

    @media (max-width: $docs-mobile-brk) {
      width: 0;

      label {
        pointer-events: all;
      }

      input {
        padding: 0;
      }

      .search-icon {
        left: -2rem;
      }
    }

    .reset-icon {
      top: 6px;
      right: 10px;
    }

    button[type=reset] {
      transform: scale(0.75);
      opacity: 0;
      transition: transform 150ms cubic-bezier(0.1, 0.7, 0.1, 1), opacity 150ms;
      pointer-events: none;
      margin: 0;
      padding: 0;
      font-size: inherit;
      background: transparent;
      border: 0;
    }
  }

  .search-results {
    top: 35px;
    opacity: 0;
    transition: opacity 400ms;
    position: absolute;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    border-radius: 0 0 2px 2px;

    .search-scroll {
      background: $docs-color-back;
      max-height: 0;
      height: 100%;
    }

    .search-result {
      word-break: break-word;
      color: $docs-color-text;

      .search-result-meta {
        padding: 0 1rem;
        padding-left: 44px;
        color: $docs-color-text;
        font-size: 0.85rem;
        line-height: 44px;
        background-color: transparentize($color: $docs-color-text, $amount: 0.93);
        scroll-snap-align: start;
      }

      .search-result-list {
        margin: 0;
        list-style: none;

        .search-result-list-item {
          box-shadow: none;
          display: block;
          outline: 0;
          scroll-snap-align: start;

          a {
            color: inherit;
            text-decoration: none;
            -webkit-tap-highlight-color: transparent;

            > div {
              margin: 0.55rem 0;
              font-weight: 400;
              font-size: 0.8rem;
              line-height: 1.4;

              > span {
                display: block;

                &.title {
                  font-weight: bold;
                  font-size: 1.1em;
                }

                &.keywords {
                  font-size: 0.8em;
                  color: $docs-color-inactive;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tag-label {
  display: inline-flex;
  align-items: center;
  margin: 0 .5em 0 0;
  padding: .4em .833em;
  min-width: 3em;
  text-align: center;
  font-size: .85714286rem;
  line-height: 1;
  vertical-align: baseline;
  background: #e8e8e8;
  color: $text-label;
  text-transform: none;
  font-weight: 700;
  border: 1px solid transparent;
  border-radius: .28571429rem;

  &.tag-label-danger {
    background: $danger;
    color: $text-contrast;
  }

  &.tag-label-success {
    background: $success;
    color: $text-contrast;
  }

  &.tag-label-info {
    background: $info;
    color: $text-contrast;
  }

  &.tag-label-warning {
    background: $warning;
  }

  &.tag-label-primary {
    background: $primary;
    color: $text-contrast;
  }

  &.tag-label-secondary {
    background: $gray-darker;
    color: $text-contrast;
  }

  &.tag-label-ghost {
    background: transparent;
  }
}

.tag-row {
  margin: 0 1.5rem;
  font-size: 0.6rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  & > .tag-label {
    font-size: 1em;
    min-width: 0;
    margin: 0;
  }
}

.quick-nav {
  color: $docs-color-quicknav-text;
  background: $docs-color-quicknav-back;
  font-size: 1.25rem;

  .quick-nav-prev {
    float: left;
    text-align: left;
    width: 25%;

    @media (max-width: $docs-tablet-brk) {
      width: 50%;
    }

    @media (max-width: $docs-mobile-brk) {
      width: 10%;

      .nav-title {
        display: none;
      }
    }
  }

  .quick-nav-next {
    float: right;
    text-align: right;
    width: 75%;

    @media (max-width: $docs-tablet-brk) {
      width: 50%;
    }

    @media (max-width: $docs-mobile-brk) {
      width: 90%;
    }
  }

  a {
    display: flex;
    padding-top: 1.4em;
    padding-bottom: 0.4em;
    transition: opacity 250ms;
    color: inherit;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;

    .nav-button {
      margin: 0.2em;
      padding: 0.4em;

      svg {
        display: block;
        width: 1.2em;
        height: 1.2em;
        margin: 0 auto;
        fill: currentcolor;
      }
    }

    .nav-title {
      position: relative;
      flex-grow: 1;
      font-size: 0.9em;
      line-height: 48px;

      > .nav-title-ellipsis {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .nav-direction {
        position: absolute;
        right: 0;
        left: 0;
        margin-top: -20px;
        font-size: 0.64em;
        opacity: 0.7;
      }
    }
  }
}

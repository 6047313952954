$docs-color-primary: #00ffad !default;
$docs-color-secondary: #192440 !default;
$docs-color-heart: #ea5395 !default;
$docs-color-text: #000 !default;
$docs-color-text-dark: #e2e2e2 !default;
$docs-color-shadow: #e5e5e5 !default;
$docs-color-shadow-dark: invert($docs-color-shadow) !default;
$docs-color-inactive: #ccc !default;
$docs-color-inactive-dark: invert($docs-color-inactive) !default;
$docs-color-back: #fff !default;
$docs-color-back-dark: #181818 !default;
$docs-color-warning: #ff9100 !default;
$docs-color-danger: #ff5252 !default;
$docs-color-purple: #a321a3 !default;
$docs-color-md-text: #24292e !default;
$docs-color-md-text-dark: invert($docs-color-md-text) !default;
$docs-color-md-code: #1b1f23 !default;
$docs-color-md-code-dark: invert($docs-color-md-code) !default;
$docs-color-md-border: #eaecef !default;
$docs-color-md-border-dark: invert($docs-color-md-border) !default;
$docs-color-md-builtin: #0086b3 !default;
$docs-color-md-builtin-dark: invert($docs-color-md-builtin) !default;
$docs-color-md-symbol: #990073 !default;
$docs-color-md-symbol-dark: invert($docs-color-md-symbol) !default;
$docs-color-md-regexp: #009926 !default;
$docs-color-md-attribute: #000080 !default;
$docs-color-md-attribute-dark: invert($docs-color-md-attribute) !default;
$docs-color-md-token: #ffea7f !default;
$docs-color-md-comment: #998 !default;
$docs-color-md-meta: #999 !default;
$docs-color-md-addition: #dfd !default;
$docs-color-md-deletion: #fdd !default;
$docs-color-md-keyword: #333 !default;
$docs-color-md-keyword-dark: invert($docs-color-md-keyword) !default;
$docs-color-md-string: #d14 !default;
$docs-color-md-string-dark: invert($docs-color-md-string) !default;
$docs-color-md-section: #900 !default;
$docs-color-md-section-dark: invert($docs-color-md-section) !default;
$docs-color-md-type: #458 !default;
$docs-color-md-literal: #008080 !default;
$docs-color-md-literal-dark: invert($docs-color-md-literal) !default;
$docs-color-md-front: #c6cbd1 !default;
$docs-color-md-front-dark: invert($docs-color-md-front) !default;
$docs-color-md-back: #f6f8fa !default;
$docs-color-md-back-dark: invert($docs-color-md-back) !default;
$docs-color-md-paper: #f8f8f8 !default;
$docs-color-md-paper-dark: invert($docs-color-md-paper) !default;
$docs-color-md-edge: #d1d5da !default;
$docs-color-md-edge-dark: invert($docs-color-md-edge) !default;
$docs-color-md-shadow: #fafbfc !default;
$docs-color-md-shadow-dark: invert($docs-color-md-shadow) !default;
$docs-color-md-line: #e1e4e8 !default;
$docs-color-md-line-dark: invert($docs-color-md-line) !default;
$docs-color-md-table: #dfe2e5 !default;
$docs-color-md-table-dark: invert($docs-color-md-table) !default;
$docs-color-md-inactive: #6a737d !default;
$docs-color-md-inactive-dark: invert($docs-color-md-inactive) !default;
$docs-color-md-kbd: #444d56 !default;
$docs-color-md-kbd-dark: invert($docs-color-md-kbd) !default;
$docs-color-md-box: #959da5 !default;
$docs-color-md-box-dark: invert($docs-color-md-box) !default;
$docs-color-success: #00c853 !default;
$docs-color-tip: #00bfa5 !default;
$docs-color-header-back: $docs-color-secondary !default;
$docs-color-header-text: $docs-color-back !default;
$docs-color-header-active: $docs-color-primary !default;
$docs-color-tabs-text-normal: #767676 !default;
$docs-color-tabs-text-active: #000 !default;
$docs-color-tabs-border-normal: #ddd !default;
$docs-color-tabs-border-active: #d8d8d8 !default;
$docs-color-sidebar-active: #0b74de !default;
$docs-color-sidebar-normal: #485766 !default;
$docs-color-sidebar-dark: invert($docs-color-sidebar-normal) !default;
$docs-color-quicknav-text: $docs-color-back !default;
$docs-color-quicknav-back: transparentize($color: $docs-color-secondary, $amount: 0.13) !default;
$docs-color-scrollbar: transparentize($color: $docs-color-text, $amount: 0.68) !default;
$docs-color-scrollbar-dark: invert($docs-color-scrollbar) !default;
$docs-color-button-normal: lighten(#192440, 10%) !default;
$docs-color-button-active: #268fff !default;
$docs-color-spinner: #56aa1c !default;

$docs-screen-brk: 1200px !default;
$docs-desktop-brk: 992px !default;
$docs-tablet-brk: 768px !default;
$docs-mobile-brk: 576px !default;

$docs-font-family-code: sfmono-regular, consolas, liberation mono, menlo, courier, monospace !default;
$docs-font-family-text: -apple-system, blinkmacsystemfont, segoe ui, helvetica, arial, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol !default;
$docs-font-family: 'Lato', sans-serif !default;

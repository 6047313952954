html, body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

html,
body,
input,
textarea,
select,
button {
  font-size: 16px;
  font-family: $docs-font-family;
  font-weight: 300;
  color: $docs-color-text;
}

body {
  display: flex;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex {
  display: flex;
}

.container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (min-width: $docs-tablet-brk) {
    max-width: 720px;
  }

  @media (min-width: $docs-desktop-brk) {
    max-width: 960px;
  }

  @media (min-width: $docs-screen-brk) {
    max-width: 1140px;
  }
}

.text-center {
  text-align: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &.center {
    justify-content: center;
  }
}

.boxes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

p {
  line-height: 1.5;
}

.responsive-image {
  max-width: 100%;
}

section {
  padding: 2em 0;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .btn {
    margin: 1em;
  }
}

.responsive-table {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.btn, a.btn {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5em 1em;
  text-decoration: none;
  border-radius: 0.25rem;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  box-sizing: border-box;
  display: inline-block;

  i {
    margin-right: 0.5em;
    position: relative;
  }

  &.primary {
    background: $docs-color-secondary;
    border: 1px solid $docs-color-secondary;
    color: $docs-color-back !important;

    &:hover, &:focus, &:active, &.active, &.hover {
      background: $docs-color-button-normal;
      color: $docs-color-back !important;
      border: 1px solid $docs-color-button-normal;
      text-decoration: none;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($docs-color-button-active, 0.5);
    }
  }
}

.sticky {
  position: sticky;
  top: 0;

  &:before,
  &:after {
    content: '';
    display: table;
  }
}

a:focus {
  outline: none;
}

#app {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-bottom: auto;
  width: 100%;
  min-height: 100%;
  background: $docs-color-back;

  body.dark & {
    background: $docs-color-back-dark;
  }
}

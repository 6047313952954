@import "/home/vsts/work/1/s/pages/page-docs/src/style.scss";
@import '../../../node_modules/@smapiot/components/style/variables';

.info-container {
  background: #f8f8f8;
  margin-bottom: -1rem;
  padding-bottom: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;

  body.dark & {
    background: invert(#f8f8f8);
  }
}

.drop-container {
  margin-top: 1rem;
  position: relative;
  color: $info;
  font-size: 0.875rem;

  .drop-label {
    margin-right: 0.5rem;
  }

  &:hover .drop {
    display: block;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    background: white;
    border: 1px solid #ccc;

    body.dark & {
      background: #222;
    }
  }

  .drop {
    display: none;
    position: absolute;
    z-index: 99999;
    top: 1.1rem;
    left: 0;

    a {
      display: block;
      padding: 0.5rem 1rem;
      text-decoration: none;
      color: $primary;

      &:hover {
        color: $secondary;
      }
    }
  }
}

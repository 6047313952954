header {
  padding: 1em;
  background: $docs-color-header-back;
}

header nav {
  .menu {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0;
    margin: 0;
    margin-top: 1em;
    flex: 1 0 auto;
    justify-content: flex-start;
    align-items: center;

    a {
      color: $docs-color-header-text;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: $docs-color-header-active;
      }

      &.active {
        color: $docs-color-header-active;
      }
    }
  }

  .language-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    &.show {
      .available {
        display: block;
      }
    }

    .separator {
      color: $docs-color-header-text;
    }

    .available {
      display: none;
      filter: grayscale(100%);
      cursor: pointer;

      &:hover {
        filter: none;
        box-shadow: 0 0 3px 3px $docs-color-primary;
        border-radius: 50%;
      }
    }

    .current {
      cursor: pointer;
    }
  }

  @media (max-width: $docs-mobile-brk) {
    &[data-open=true] .menu {
      display: flex;
    }

    .language-selector .separator {
      display: none;
    }

    .menu {
      display: none;
      flex: 1 100%;
      flex-direction: column;
    }
  }
}

header .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $docs-color-header-text;
    padding-right: 1rem;

    img {
      max-height: 2em;
      margin-right: 0.5em;
    }

    > span {
      font-size: 1.2em;
    }

    .brand-name {
      font-weight: bold;
    }
  }

  .hamburger {
    display: none;

    a {
      color: $docs-color-header-text;
    }
  }

  @media (max-width: $docs-mobile-brk) {
    .hamburger {
      display: block;
      margin-left: 1rem;
    }
  }
}

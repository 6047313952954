@import "/home/vsts/work/1/s/pages/page-docs/src/style.scss";
div.content .section-nav {
  top: 2rem !important;
}

div.content .toc-nav {
  top: 2rem !important;
}

#app {
  height: auto !important;
  min-height: 100%;
}

body {
  display: block !important;
}

.app-center {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.search-dialog .search-details {
  z-index: 2 !important;
}

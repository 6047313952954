footer {
  background: $primary;
  padding: $space-footer-gap 0;

  .logo > img {
    max-width: 180px;
  }

  .footer-columns {
    flex-direction: column;

    & > div {
      flex: 1;

      @media (max-width: $tablet-brk) {
        &:first-child {
          flex: 1 100%;
        }
      }
    }

    @media (min-width: $mobile-brk) {
      flex-direction: row;
    }
  }

  .footer-menu {
    margin-top: $space-footer-gap;
    padding-left: 1em;

    @media (min-width: $tablet-brk) {
      &:first-child {
        margin-top: 0;
      }
    }

    h3 {
      color: $text-contrast;
      font-weight: bold;
      margin-top: 0;
      text-transform: uppercase;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin: 1rem 0;

        a {
          color: $text-contrast;
          text-decoration: none;

          i {
            margin-right: 0.5em;
          }

          .fa-phone {
            transform: rotate(100deg);
          }
        }
      }
    }
  }

  .copy-rights {
    color: $text-contrast;
    margin: 0 auto;
    font-weight: 300;
    margin-top: $space-footer-gap;
    margin-bottom: 0;
    padding: 0 1em;

    a {
      color: $text-contrast;
    }

    @media (min-width: $mobile-brk) {
      text-align: center;

      br {
        display: none;
      }
    }
  }
}

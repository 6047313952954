.btn,
button,
button.btn {
  background: $primary;
  color: $text-contrast;
  border-radius: 5px;
  padding: 0.5em 0.8em;
  font-size: .8em;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  cursor: pointer;

  &:hover, &:focus {
    background: $primary-lighter;
    color: $text-contrast;
    outline: none;
  }

  &:disabled {
    pointer-events: none;
    background: $gray-darker;
  }
}

.btn.btn-danger {
  background: $danger;

  &:hover, &:focus {
    background: $danger-lighter;
  }
}

.btn.btn-success {
  background: $success;

  &:hover, &:focus {
    background: $success-lighter;
  }
}

.btn.btn-info {
  background: $info;

  &:hover, &:focus {
    background: $info-lighter;
  }
}

.btn.btn-warning {
  background: $warning;

  &:hover, &:focus {
    background: $warning-lighter;
  }
}

.btn.btn-secondary {
  background: $tertiary;

  &:hover, &:focus {
    background: $tertiary-lighter;
  }
}

.btn.btn-static {
  background: $gray-darker;

  &:hover, &:focus {
    background: $gray-medium;
  }
}

.btn.btn-ghost {
  background: transparent;
  opacity: 90%;

  &:hover, &:focus {
    background: transparent;
    opacity: 100%;
  }
}

.btn-play {
  padding: 0;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  border-radius: 50%;
  text-align: center;
}

.button-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -0.5em;

  > .btn {
    margin: 0.5em;
  }
}

ul.toc-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    width: 100%;
    max-width: 100%;
    position: relative;
    margin: 0;
    padding-left: 0;
    box-sizing: border-box;

    a {
      color: $docs-color-sidebar-active;
      font-size: 0.875rem;
      padding: 0.375rem 0;
      margin-left: 0.25rem;
      display: block;
      position: relative;
      box-sizing: border-box;
      border-left: 1px solid $docs-color-shadow;
      text-decoration: none;


      body.dark & {
        border-left: 1px solid $docs-color-shadow-dark;
      }

      div {
        text-overflow: ellipsis;
        overflow-x: hidden;
      }

      &.level-1 {
        padding-left: 12px;
      }

      &.level-2 {
        padding-left: 28px;
      }

      &.level-3 {
        padding-left: 44px;
      }

      &.level-4 {
        padding-left: 60px;
      }

      &.level-5 {
        padding-left: 76px;
      }

      &::before {
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        transform: translateY(-50%);
        top: 50%;
        left: -4px;
        display: none;
        z-index: 2;
        background: $docs-color-shadow;
        border-radius: 100%;

        body.dark & {
          background: $docs-color-shadow-dark;
        }
      }

      &.children::before {
        display: block;
      }
    }

    ul.toc-list {
      display: none;
    }

    &.active {
      > ul.toc-list {
        display: block !important;
      }

      a {
        font-weight: bold;
      }

      > a::before {
        display: block;
        background: $docs-color-sidebar-active !important;
      }
    }
  }
}

.section-nav-title {
  font-size: 0.75rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: transparentize($color: $docs-color-sidebar-normal, $amount: 0.4);
  text-transform: uppercase;
  padding: 0.375rem 0;
  margin: 0;

  body.dark & {
    color: transparentize($color: $docs-color-sidebar-dark, $amount: 0.4);
  }
}

ul.section-nav-list {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    margin-bottom: 0.5625rem;
    padding-left: 0;

    a {
      display: block;
      position: relative;
      font-size: 0.875rem;
      color: $docs-color-sidebar-normal;
      font-weight: 500;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      text-decoration: none;

      body.dark & {
        color: $docs-color-sidebar-dark;
      }

      &.active {
        color: $docs-color-sidebar-active !important;
        font-weight: bold;
      }
    }
  }
}

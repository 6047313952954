.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-large {
  font-size: 3rem;
}

.slogan {
  font-weight: bold;
}

.text-block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.5em;

  .text-block-teaser {
    flex: 1;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-block-content {
    >h2 {
      margin-top: 0;
    }
  }

  &.normal {
    flex-direction: row;
  }

  &.reverse {
    flex-direction: row-reverse;
  }
}

.text-box {
  padding: 1em;
}

.text-card-content {
  span.headline {
    color: $primary;
    font-weight: 600;
    font-size: 0.8em;
  }

  h2.headline {
    font-size: 1.3em;
    margin-top: 0;
  }

  .headline {
    text-transform: uppercase;
  }

  p {
    color: $gray-darker;
  }
}

blockquote {
  color: $gray-darker;
  size: 1.2em;
  font-style: italic;
  padding: 1em;
  margin: 0 auto;
  margin-top: 30px;
  position: relative;

  &::before {
    content: '\f10d';
    color: $primary;
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    font-style: normal;
    position: absolute;
    left: 0;
    right: 0;
    top: -10px;
    margin: auto;
  }
}

// colors
$primary: #56AA1C !default;
$primary-lighter: lighten($primary, 10%) !default;
$secondary: #78bb49 !default;
$secondary-lighter: lighten($secondary, 10%) !default;
$tertiary: #745a13 !default;
$tertiary-lighter: lighten($tertiary, 10%) !default;
$gray: #e1e3e5 !default;
$gray-lighter: #F7F6F5 !default;
$gray-darker: #8E8989 !default;
$gray-medium: lighten($gray-darker, 10%) !default;
$gray-faded: transparentize($color: $gray-darker, $amount: 0.3) !default;
$text-foreground: #101010 !default;
$text-background: #fbfbfb !default;
$text-contrast: #ffffff !default;
$text-faded: transparentize($color: $text-contrast, $amount: 0.13) !default;
$text-label: rgba(0,0,0,.6) !default;
$danger: #d61c1c !default;
$danger-lighter: lighten($danger, 10%) !default;
$success: #499e0d !default;
$success-lighter: lighten($success, 10%) !default;
$info: #1D72F3 !default;
$info-lighter: lighten($info, 10%) !default;
$warning: #FFC007 !default;
$warning-lighter: lighten($warning, 10%) !default;
$shadow: #f2f2f2 !default;

// animations
$accordion-transition-duration: 0.15s !default;
$accordion-transition-delay: 0.05s !default;

// dimensions
$space-footer-gap: 3rem !default;
$space-input-label: 10px !default;

// fonts
$font-family-normal: 'Lato', sans-serif !default;
$font-family-heading: Montserrat, sans-serif !default;

// break points
$desktop-brk: 1200px !default;
$tablet-brk: 768px !default;
$mobile-brk: 576px !default;

@mixin show-accordion {
  opacity: 1;

  transform: translateY(0);
  transition-timing-function: ease-in-out;
  transition-duration: $accordion-transition-duration;
  transition-delay: $accordion-transition-delay;
  transition-property: padding, max-height, opacity, transform;

  max-height: 100rem;
}

@mixin hide-accordion {
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;

  transform: translateY(-1rem);
  transition-timing-function: ease-in-out;
  transition-duration: $accordion-transition-duration;
  transition-delay: $accordion-transition-delay;
  transition-property: padding, max-height, opacity, transform;

  max-height: 0;
  overflow: hidden;
  z-index: 1;
  position: relative;
}

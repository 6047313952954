img {
  max-width: 100%;
  flex: 0 0 auto;
  border: none;

  &.circle {
    border-radius: 50%;
  }

  &.shadow {
    box-shadow: 0 5px 15px #666;
  }

  &.midsize {
    height:180px;
    width:180px;
  }
}

.video {
  position: relative;
  flex-basis: 450px;

  img {
    margin: auto;
  }

  .btn-play {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

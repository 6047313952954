.stepper-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;

  .stepper-step {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .stepper-connector {
    > hr {
      border-top: 1px solid $gray-darker;
    }
  }
}

.stepper-preview {
  display: flex;
  align-items: center;
  text-align: left;

  [data-active=true] & {
    font-weight: bold;
  }

  .stepper-indicator {
    display: flex;
    flex-shrink: 0;
    padding-right: 0.5rem;

    > svg {
      user-select: none;
      color: $gray-darker;
      width: 1em;
      height: 1em;
      fill: currentcolor;
      flex-shrink: 0;
      font-size: 1.5rem;
      display: block;
      transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      [data-active=true] & {
        color: $primary;
      }

      text {
        fill: $text-faded;
        font-size: 0.75rem;
        font-family: $font-family-normal;
      }
    }
  }

  .stepper-label {
    width: 100%;
    color: $gray-faded;
    text-align: left;

    [data-active=true] & {
      color: $primary;
    }

    > span {
      font-size: 0.875rem;
      line-height: 1.43;
      letter-spacing: 0.01071em;
      display: block;
      transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
}

.stepper-vertical {
  display: flex;
  flex-direction: column;

  .stepper-preview {
    padding: 0.5rem 0;
  }

  .stepper-connector {
    margin-left: 0.75rem;

    > hr {
      border-left: 1px solid $gray-darker;
      min-height: 1.5rem;
    }
  }
}

.stepper-content {
  margin-left: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 0.5rem;
  border-left: 1px solid $gray-darker;

  .stepper-content-form {
    padding: 0.5rem 0;
  }

  > div {
    min-height: 0px;
    height: auto;
    overflow: visible;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.stepper-connector {
  flex: 1 1 auto;

  > hr {
    display: block;
    border: none;
    margin: 0;
  }
}

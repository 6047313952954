.tabs {
  justify-content: space-between;

  > div.tab-content {
    padding: 0.8rem;
  }

  > ul.tab-bar {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    border-bottom: 1px solid $gray-medium;
    max-width: 100%;

    li {
      padding: 0;

      button {
        display: block;
        color: black;
        background-color: white;
        text-align: center;
        text-decoration: none;
        text-transform: capitalize;
        padding: 0.4rem 0.5rem;
        width: 100%;
      }

      .btn-secondary {
        background-color: white;
        border-bottom: 1px solid white;
        width: 100%;
      }
    }
  }

  > ul.tab-bar-line {
    button {
      border-radius: 0;
      border-bottom: 2px solid $primary;
    }
  }

  > ul.tab-bar-enclosed {
    button {
      border-radius: 4px 4px 0 0;
      border: 1px solid $gray;
    }

    .btn-secondary {
      border-color: transparent;
    }
  }

  > ul.tab-bar-fitted {
    li {
      flex: 1;
    }
  }
}

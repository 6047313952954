@use 'sass:math';

$border-width: 4px;
$basic-spinner-dimensions: 50px;
$main-spinner-dimensions: $basic-spinner-dimensions - $border-width * 2;
$small-spinner-dimensions: $main-spinner-dimensions * 0.7;

.app-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  margin: auto;
}

.spinner {
  position: relative;
  width: $basic-spinner-dimensions;
  height: $basic-spinner-dimensions;
  font-size: 0;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
  }
}

.spinner.circles {
  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes anti-rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }

  &:before {
    width: $main-spinner-dimensions;
    height: $main-spinner-dimensions;
    border-bottom-color: $docs-color-spinner;
    border-right-color: $docs-color-spinner;
    border-top-color: rgba($docs-color-spinner, 0);
    border-left-color: rgba($docs-color-spinner, 0);
    top: 0;
    left: 0;
    animation: rotate-animation 1s linear 0s infinite;
  }

  &:after {
    width: $small-spinner-dimensions;
    height: $small-spinner-dimensions;
    border-bottom-color: $docs-color-spinner;
    border-right-color: $docs-color-spinner;
    border-top-color: rgba($docs-color-spinner, 0);
    border-left-color: rgba($docs-color-spinner, 0);
    top: math.div($main-spinner-dimensions - $small-spinner-dimensions, 2);
    left: math.div($main-spinner-dimensions - $small-spinner-dimensions, 2);
    animation: anti-rotate-animation 0.85s linear 0s infinite;
  }
}

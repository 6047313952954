.clipboard-text {
  padding-bottom: 1px;

  output {
    display: block;
    white-space: pre-wrap;
    word-break: break-all;
    text-align: center;
    margin-bottom: 32px;
    padding: 0.75em;
    background: lightyellow;
    font-size: 1.5em;
    font-weight: bold;
    color: #555;
    cursor: pointer;
  }

  .copy-info {
    font-size: 0.7em;
    text-align: center;
    margin-top: -16px;
    height: 16px;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  padding: 0.2em;
  position: relative;

  .bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    padding: 0.2em;
  }
  
  button {
    margin: 0.2em;
  }

  .panel-title{
    position: relative;
    left: 0%;
    margin: 0.2em;
    width: 21em;
  }
}
